<template>
  <div class="responses">
    <h1 id="error" v-if="error != ''">{{ error }}</h1>
    <h1 id="created" v-if="create != ''">{{ create }}</h1>
    <p class="textInfo" v-if="create != ''" ><span class="labelInfo">Firstname:</span> {{firstname}}</p>
    <p class="textInfo" v-if="create != ''" ><span class="labelInfo">Lastname:</span> {{lastname}}</p>
    <p class="textInfo" v-if="create != ''" ><span class="labelInfo">First discount use:</span> {{firstDiscount}}</p>
    <p class="textInfo" v-if="create != ''" ><span class="labelInfo">Tuesday discount use:</span> {{tuesdayDiscount}}</p>
    <p class="textInfo" v-if="create != ''" ><span class="labelInfo">Last time use discount:</span> {{modificationDate}}</p>
    <p class="textInfo" v-if="create != ''" ><span class="labelInfo">User date created:</span> {{creationDate}}</p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      create: "",
      error: "",
      firstname: "",
      lastname: "",
      firstDiscount: "",
      tuesdayDiscount: "",
      modificationDate: "",
      creationDate: "",
    };
  },
  created() {
    this.getDiscount();
  },
  methods: {
    async getDiscount() {
      try {
        const discount_response = await axios.get(
          `${this.serverHost}/tuesdaydiscount/${this.customerid}`
        );
        this.create = discount_response.data.create;
        this.firstname = discount_response.data.customer_result.firstname;
        this.lastname = discount_response.data.customer_result.lastname;
        this.firstDiscount = discount_response.data.customer_result.discount_first;
        this.tuesdayDiscount = discount_response.data.customer_result.discount_tuesday;
        this.modificationDate = new Date(discount_response.data.customer_result.updatedAt).toLocaleString();
        this.creationDate = new Date(discount_response.data.customer_result.createdAt).toLocaleString();
      } catch (err) {
        this.error = err.response.data.message;
      }
    },
  },
  props: {
    customerid: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textInfo {
  color:white;
  font-size: 130%;
}
.labelInfo {
  color: rgb(133, 133, 133);
  text-transform: uppercase;
}
</style>
