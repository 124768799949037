<template>
  <div class="responses">
    <Preloader v-if="loading" />
    <p id="error" v-if="error != ''">{{ error }}</p>
    <p id="created" v-if="created != ''">{{ created }}</p>
  </div>
  <div class="form">
    <input
      type="text"
      placeholder="Firstname"
      required
      v-model="firstname"
      @click="ChangingHandler"
    />
    <input
      type="text"
      placeholder="Lastname"
      required
      v-model="lastname"
      @click="ChangingHandler"
    />

    <input
      type="email"
      placeholder="Enter your email"
      required
      v-model="email"
      @click="ChangingHandler"
    />

    <div @click="saveEmail" class="button">
      <p class="takeYourDiscount" style="font-family: arial">GET IT NOW*</p>
    </div>
  </div>
  <p style="color:white">*by clicking on confirm, you agree to receive offers by e-mail from your favorite restaurant.</p>
  <div class="discounts">
    <img class="offer" src="../assets/offer.jpeg" />
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      error: "",
      created: "",
      loading: false,
    };
  },
  methods: {
    async saveEmail() {
      if (this.firstname !== "" && this.lastname !== "" && this.email !== "") {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        const matches = regex.exec(this.email);
        if (matches !== null) {
          this.loading = true;
          try {
            const creation_response = await axios.post(
              `${this.serverHost}/enteremail`,
              {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
              }
            );
            this.firstname = "";
            this.lastname = "";
            this.email = "";
            this.created = creation_response.data.create;
          } catch (err) {
            this.error = err.response.data.message;
            this.email = "";
          }
          this.loading = false;
        } else {
          this.error = "Provide valid email";
        }
      } else {
        this.error =
          "Provide your firstname, lastname and email to get your discount";
      }
    },
    async ChangingHandler() {
      this.error = "";
      this.created = "";
    },
  },
  created() {},
  components: { Preloader },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.test {
  border: 1px solid red;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
}
.offer {
  max-width: 600px;
  width: 100%;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  background: transparent;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  height: 40px;
  width: 100%;
}

.button:hover {
  border: 4px solid #fff82f;
  background-color: rgb(21, 21, 21);
  box-shadow: 0px -1px 60px 0px #fffb93bc;
}

input[type="email"],
input[type="text"] {
  font-size: 24px;
  text-align: center;
}

input[type="email"]::placeholder,
input[type="text"]::placeholder {
  text-align: center;
  color: white;
}
input[type="email"]:focus,
input[type="text"]:focus {
  outline: none !important;
  border-color: #faf320;
  color: #faf320;
}

.takeYourDiscount {
  width: 100%;
  text-align: center;
  font-size: 280%;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  text-shadow: 0 0 21px #fff, 0 0 42px #faf320, 0 0 82px #faf320,
    0 0 92px #faf320, 0 0 151px #faf320;
  margin-bottom: 20px;
}
@media (max-width: 282px) {
  .takeYourDiscount {
    font-size: 240%;
  }
}
@media (max-width: 244px) {
  .takeYourDiscount {
    font-size: 210%;
  }
}

.button {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  border: 4px solid #faf320;
  height: fit-content;
  border-radius: 20px;
  background-color: rgb(21, 21, 21);
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px -1px 25px 0px #faf320bc;
  cursor: pointer;
  margin-top: 20px;
}
</style>
