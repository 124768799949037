import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DiscountView from "../views/DiscountView.vue";
const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/firstdiscount/:customerid",
    name: "FirstDiscountView",
    component: DiscountView,
    props: { first: true },
  },
  {
    path: "/tuesdaydiscount/:customerid",
    name: "TuesdayDiscountView",
    component: DiscountView,
    props: { first: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
