import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//App.config.globalProperties.$serverHost = 'https://api.meyourestaurant-discount.co.uk'
const app = createApp(App);

app.config.globalProperties.serverHost = 'https://api.meyourestaurant-discount.co.uk'
//app.config.globalProperties.serverHost = 'http://localhost:6300'

app.use(router).mount('#app');



