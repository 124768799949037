<template>
  <div class="responses">
    <h1 id="error" v-if="error != ''">{{ error }}</h1>
    <h1 id="created" >{{ create }}</h1>
        <p class="textInfo"  ><span class="labelInfo">Firstname:</span> {{firstname}}</p>
    <p class="textInfo"  ><span class="labelInfo">Lastname:</span> {{lastname}}</p>
    <p class="textInfo"  ><span class="labelInfo">First discount use:</span> {{firstDiscount === false ? 'NO' : 'YES'}}</p>
    <p class="textInfo"  ><span class="labelInfo">Tuesday discount use:</span> {{tuesdayDiscount}}</p>
    <p class="textInfo"  ><span class="labelInfo">Last time use discount:</span> {{modificationDate}}</p>
    <p class="textInfo"  ><span class="labelInfo">User date created:</span> {{creationDate}}</p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      create: "",
      error: "",
            firstname: "",
      lastname: "",
      firstDiscount: "",
      tuesdayDiscount: "",
      modificationDate: "",
      creationDate: "",
    };
  },
  created() {
    this.getDiscount();
  },
  methods: {
    async getDiscount() {
      try {
        const discount_response = await axios.get(
          `${this.serverHost}/firstdiscount/${this.customerid}`
        );
        this.create = discount_response.data.create;
        this.firstname = discount_response.data.customer.firstname;
        this.lastname = discount_response.data.customer.lastname;
        this.firstDiscount = discount_response.data.customer.discount_first;
        this.tuesdayDiscount = discount_response.data.customer.discount_tuesday;
        this.modificationDate = new Date(discount_response.data.customer.updatedAt).toLocaleString();
        this.creationDate = new Date(discount_response.data.customer.createdAt).toLocaleString();
      } catch (err) {
        this.error = err.response.data.message;
        this.firstname = err.response.data.customer.firstname;
        this.lastname = err.response.data.customer.lastname;
        this.firstDiscount = err.response.data.customer.discount_first;
        this.tuesdayDiscount = err.response.data.customer.discount_tuesday;
        this.modificationDate = new Date(err.response.data.customer.updatedAt).toLocaleString();
        this.creationDate = new Date(err.response.data.customer.createdAt).toLocaleString();
      }
    },
  },
  props: {
    customerid: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textInfo {
  color:white;
  font-size: 130%;
}
.labelInfo {
  color: rgb(133, 133, 133);
  text-transform: uppercase;
}</style>
