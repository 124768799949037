<template>
  <Preloader v-if="loading" />
  <div v-if="!authenticated" class="login">
    <p id="error" v-if="message != ''">{{ message }}</p>
    <label for="username">Username </label>
    <input
      type="text"
      placeholder="Enter your username"
      id="username"
      name="username"
      required
      v-model="username"
      @click="ChangingHandler"
    />
    <label for="password">Password </label>
    <input
      type="password"
      placeholder="Enter your password"
      id="password"
      name="password"
      required
      v-model="password"
      @click="ChangingHandler"
    />
    <button class="btn" @click="logIn">Log in</button>
  </div>
  <div v-if="authenticated">
    <FirstDiscount v-if="first" v-bind:customerid="$route.params.customerid" />
    <TuesdayDiscount v-else v-bind:customerid="$route.params.customerid" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import FirstDiscount from "@/components/FirstDiscount.vue";
import TuesdayDiscount from "@/components/TuesdayDiscount.vue";
import Preloader from "@/components/Preloader.vue";
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {
  name: "DiscountView",
  components: { FirstDiscount, TuesdayDiscount, Preloader },
  props: {
    first: Boolean,
  },
  data() {
    return {
      username: "",
      password: "",
      authenticated: false,
      message: "",
      loading: false,
    };
  },
  created() {
    this.logIn();
  },
  methods: {
    async logIn() {
      this.loading = true;
      try {
        const login_response = await axios.post(
          `${this.serverHost}/login`,
          {
            username: this.username,
            password: this.password,
          }
        );
        this.authenticated = login_response.data.authenticated;
        this.loading = false;
      } catch (err) {
        this.message = err.response.data.message;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
input {
  background: transparent;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
}
label {
  color: white;
  margin: 10%;
}
.login {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn {
  margin: 10%;
  border: solid;
  border-radius: 10px;
  background-color: transparent;
  font-size: 150%;
  color: #faf320;
  cursor: pointer;
}
</style>
