<template>
  <div class="main">
    <div>
      <img class="logo" src="./assets/logo.jpeg" />
    </div>
    <router-view />
  </div>
</template>
<style>
body {
  background: black;
    font-family: Arial, Helvetica, sans-serif;
}
.logo {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.main {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.responses {
  text-align: center;
  height: auto;
}
#error {
  font-family: Arial, Helvetica, sans-serif;
  color: orangered;
}

#created {
  font-family: Arial, Helvetica, sans-serif;
  color: chartreuse;
}
</style>
