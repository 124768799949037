<template>
  <WelcomeView />
</template>

<script>
// @ is an alias to /src
import WelcomeView from "@/components/Welcome.vue";

export default {
  name: "HomeView",
  components: {
    WelcomeView,
  },
};
</script>
<style></style>
